/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable value-no-vendor-prefix */

/*
* defaults, why?:
* https://www.joshwcomeau.com/css/custom-css-reset/
*/

/*
  Use a better box model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

/*
  Remove default margin & padding
*/
* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  min-height: 100dvh;
}

body {
  font-size: 16px;
  font-family: Inter, system-ui, 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
}

html {
  /* Prevent font scaling in landscape while allowing user zoom */
  -webkit-text-size-adjust: 100%;
}

/*
  Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/*
  Remove built-in form typography styles
*/
input,
button,
textarea,
optgroup,
select {
  margin: 0;
  font: inherit;
}

/**
Remove the inheritance of text transform in Edge and Firefox.
*/
button,
select {
  text-transform: none;
}

/**
Correct the inability to style clickable types in iOS and Safari.
*/
button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  appearance: button;
}

/*
  Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/* Remove default padding and style */
ul[class],
ol[class] {
  padding: 0;
  list-style: none;
}

/* Remove list styles (bullets/numbers) */
ol,
ul,
menu {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/**
Add the correct height in Firefox.
Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
*/
hr {
  height: 0;
  color: inherit;
}

/**
Add the correct font weight in Edge and Safari.
*/
b,
strong {
  font-weight: bolder;
}

/**
Improve consistency of default fonts in all browsers. (https://github.com/sindresorhus/modern-normalize/issues/3)
Correct the odd 'em' font sizing in all browsers.
*/
code,
kbd,
samp,
pre {
  font-size: 1em;
  font-family: ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo,
    monospace;
}

/**
Add the correct font size in all browsers.
*/
small {
  font-size: 80%;
}

/**
Prevent 'sub' and 'sup' elements from affecting the line height in all browsers.
*/
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

legend {
  padding: 0;
}

table {
  text-indent: 0;
  border-color: inherit;
}

/**
Remove the additional ':invalid' styles in Firefox.
See: https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737
*/
:-moz-ui-invalid {
  box-shadow: none;
}

/**
Create a root stacking context
*/
/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
:global(#root) {
  height: 100%;
  isolation: isolate;
}

/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
:global(#__next) {
  height: 100%;
  isolation: isolate;
}

/* making sure hidden is hidden */
[hidden] {
  display: none !important;
}
