@use 'sass:map';

// spacings
$simple-padding: 1.5rem;

// focus
$focus-outline-color: var(--blue-400);

// borders
$border-radius-default: 0.5rem;
$border-radius-full: 1rem;
$border-radius-half: 0.25rem;
$border-radius-circle: 100%;

// breakpoints
$breakpoints: (
  'xs': 24em,
  'sm': 32em,
  'md': 50em,
  'lg': 72em,
);
$breakpoints-xsmall: map.get($breakpoints, 'xs');
$breakpoints-small: map.get($breakpoints, 'sm');
$breakpoints-medium: map.get($breakpoints, 'md');
$breakpoints-large: map.get($breakpoints, 'lg');

// typography
$primary-font: 'Inter', system-ui, 'Segoe UI', roboto, helvetica, arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-small: 0.875rem;
$root-font: 16px;

// inputs
$input-border-radius: $border-radius-default;
$input-padding-x: 1rem;
$input-padding-y: 0.75rem;

// Selection/hover colors
$hover-bg-color: var(--blue-050);
