.SideBarNav {
  border-right: 1px solid var(--gray-light-100);
  height: 100%;
  overflow-y: auto;

  // Push settings to bottom of sidebar
  a[data-id*='settings'] {
    margin-top: auto;
  }

  // Push status to bottom of sidebar, if present
  a[data-id*='status'] {
    margin-top: auto;
  }

  a[data-id*='status'] + a[data-id*='profile'] {
    margin-top: 0;
  }
}
