@use 'sass:meta';
@use './themes/light';
@use './themes/dark';
@use './vars/typo';
@use './vars/space';
@use './reset';
@use 'base';
@use 'base/globals';
@import 'react-loading-skeleton/dist/skeleton.css';
@import 'react-phone-number-input/style.css';

:root {
  // add spacing variables
  @each $name, $value in meta.module-variables('space') {
    --#{$name}: #{$value};
  }

  // add light theme variables
  @each $name, $value in meta.module-variables('light') {
    --#{$name}: #{$value};
  }

  // add space variables
  @each $name, $value in meta.module-variables('space') {
    --#{$name}: #{$value};
  }

  // add dark theme variables
  @media (prefers-color-scheme: dark) {
    @each $name, $value in meta.module-variables('dark') {
      --#{$name}: #{$value};
    }
  }
}

html,
body {
  font-family: globals.$primary-font;
}

html,
body,
input {
  @include typo.body-md-medium;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include typo.subheading-md-medium;
}

*:focus {
  outline-color: globals.$focus-outline-color;
}
