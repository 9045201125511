.BusinessDesktop {
  display: grid;
  grid-template-areas:
    'header  header'
    'sidebar main';
  grid-template-columns: 200px 1fr;
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100dvh;

  &--sidebar-collapsed {
    grid-template-columns: 80px 1fr;
  }

  &_header {
    grid-area: header;
  }

  &_sidebar {
    grid-area: sidebar;
    overflow: hidden;
  }

  &_container {
    position: relative;
    grid-area: main;
    padding: 0;

    /*
    do not change, this one should not scroll,
    use a layout template with scrollable content
    */
    overflow: hidden;
  }
}
